import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import * as VueGoogleMaps from "vue2-google-maps";
import VueLazyload from "vue-lazyload";
import VueLoading from "vuejs-loading-plugin";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// import { BootstrapVue } from "bootstrap-vue";
import Toasted from "vue-toasted";
import store from "./store";
import "./axios";
import moment from "moment/moment";
import { Carousel, CarouselItem } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';


// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
import "vue-typeahead-bootstrap/dist/VueTypeaheadBootstrap.css";

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);

// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: "/img/logo_inverted.png",
  loading: "/img/logo_inverted.png",
  attempt: 1,
});

Vue.use(VueLoading);
Vue.use(VueLoading, {
  dark: true, // default false
  text: "Loading ...", // default 'Loading'
  loading: true, // default false
  background: "rgb(255,255,255)", // set custom background
  // classes: ['myclass'] // array, object or string
});

Vue.config.productionTip = false;

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyD2aMS3Zy5ru63unlPgw1uV4v-fa7iRDfU",
    libraries: "places",
    sensor: false,
  },
});

Vue.filter("toCurrency", function(value) {
  if (typeof value !== "number") {
    try {
      value = parseFloat(value);
    } catch {
      return value;
    }
  }
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  });
  return formatter.format(value);
});

Vue.filter("formatDate", function(value) {
  if (value) {
    return moment(String(value)).format("MM/DD/YYYY");
  }
});

Vue.use(Toasted, {
  // pass the icon name as string
  router,
  theme: "toasted-primary",
  position: "bottom-center",
  duration: 2000,
});

Vue.use(Carousel);
Vue.use(CarouselItem);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
