import axios from "axios";
import { parseAPIErrors } from './utils';
import {getCache, setCache} from "./cache";
const isProduction = process.env.NODE_ENV === 'production';

console.log(process.env.NODE_ENV)
axios.defaults.baseURL = process.env.VUE_APP_API_URL

axios.defaults.headers['Content-Type'] = 'application/json';
axios.defaults.headers['Access-Control-Allow-Origin'] = '*';
if (localStorage.getItem('token')) {
    axios.defaults.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')
}

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    return response
}, function (error) {
    console.log(error.response);
    if (error.response && error.response.status === 401) {
        // localStorage.removeItem('vuex');
        localStorage.removeItem('token');
        return new Promise((resolve, reject) => {
            reject(error);
        });
    }

    if (error.config || error.response) {

        if (error.config.url === '/api/token/refresh' || (error.response && error.response.message === 'Account is disabled.')) {
            localStorage.removeItem('token');
            localStorage.removeItem('refresh');
            this.$router.push({name: 'home'});

            return new Promise((resolve, reject) => {
                reject(error);
            });
        }
    }

    error.backendErrors = [];
    let message = ''

    if (error.response) {
        if (error.response.status === 404) {
            error.backendErrors.push('Resource not found')
        } else if (error.response.status === 403) {
            error.backendErrors.push('Permission denied')
        } else if (error.response.status === 429) {
            error.backendErrors.push(message)
        } else if (error.response.status === 500) {
            error.backendErrors.push('A server error occurred')
        } else if (error.response.data) {
            if (error.response.data.global) {
                error.backendErrors.push(error.response.data.global)
            } else {
                error.rawPayload = error.response.data;
                let parsedErrors = parseAPIErrors(error.response.data);
                error.backendErrors = [...error.backendErrors, ...parsedErrors]
            }
        }
    }

    if (error.backendErrors.length === 0) {
        error.backendErrors.push('An unknown error occurred, ensure your are connected to the internet')
    }

    // Do something with response error
    // Try request again with new token
    if (localStorage.getItem('refresh')) {
        return new Promise((resolve, reject) => {
            axios.post('token/refresh/', {refresh: localStorage.getItem('refresh')}).then(response => {
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('refresh', response.data.refresh_token);
                resolve(response.data.token);
            }).catch((error) => {
                reject(error);
            });
        }).then((token) => {
            // New request with new token
            const config = error.config;
            config.headers['Authorization'] = `Bearer ${token}`;

            return new Promise((resolve, reject) => {
                axios.request(config).then(response => {
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                })
            });
        }).catch((error) => {
            Promise.reject(error);
        });
    }
    return Promise.reject(error);
})

// const refreshAuth = (failedRequest) => {
//     if (this.$store.state.auth.oauth.accessToken) {
//         console.log('Failed request, refreshing auth…')
//         // maybe the token was expired, let's try to refresh it
//         return this.$store.dispatch('auth/refresh-token').then(() => {
//             failedRequest.response.config.headers['Authorization'] = this.$store.getters["auth/header"];
//             return Promise.resolve();
//         })
//     } else {
//         return Promise.resolve();
//     }
// };


// createAuthRefreshInterceptor(axios, refreshAuth);

// Axios for home page
const homeAxios = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
    }
});

homeAxios.interceptors.request.use(
  (config) => {
    const cachedData = getCache(config.url);
    if (cachedData) {
      // Return a Promise that resolves with the cached data as a response-like object
      console.log('Returning cached data');
      return Promise.reject({ cachedData });
    }
    console.log('Making a new request');
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
homeAxios.interceptors.response.use(
  (response) => {
    // Cache the response data
    setCache(response.config.url, response.data);
    return response;
  },
  (error) => {
    if (error.cachedData) {
      // Return the cached data if present
      console.log('Returning cached data');
      return Promise.resolve({ data: error.cachedData });
    }
    
    return Promise.reject(error);
  }
);

export default homeAxios;


