import Vue from 'vue';
import Vuex from 'vuex';
import axios from "axios";

Vue.use(Vuex);

const state = {
    categories: null
};

const getters = {
    categories: (state) => {
        return state.categories;
    }
};

const actions = {
    getCategories ({commit, dispatch, state}) {
        axios.get('shops/categories').then((response) => {
            commit('updateCategories', response.data);
        });
    },
};

const mutations = {
    updateCategories(state, categories) {
        state.categories = categories;
    }
};

export default {
    // We're using namespacing
    // in all of our modules.
    namespaced: true,
    mutations,
    actions,
    getters,
    state,
};


