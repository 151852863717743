import Vue from "vue";
import _ from "lodash";
import VueRouter from "vue-router";
import auth from "../middleware/auth";
import store from "../store/index.js";
import middlewarePipeline from "./middlewarePipeline";
// import Home from "../views/pages/Home/Home.vue";
// import HomeStoreFront from "../views/pages/Home/HomeStoreFront.vue";
const Desktop = () => import("../views/pages/Home/Desktop.vue");
const Home = () => import("../views/pages/Home/Home.vue");
const LandingPage = () => import("../views/pages/Home/LandingPage.vue");
const HomeStoreFront = () => import("../views/pages/Home/HomeStoreFront.vue");
const HomeStoreFront2 = () => import("../views/pages/Home/HomeStoreFront2.vue");
const Services = () => import("../views/pages/Home/Services.vue");
const HomeStoreFront3 = () => import("../views/pages/Home/HomeStoreFront3.vue");
const HomeStoreFront4 = () => import("../views/pages/Home/HomeStoreFront4.vue");
const HomeStoreFront5 = () => import("../views/pages/Home/HomeStoreFront5.vue");
const HomeShopNow = () => import("../views/pages/Home/HomeShopNow.vue");
const HomeButchery = () => import("../views/pages/Home/HomeShopButchery.vue");

const AdminLayout = () => import("../views/pages/Layouts/AdminLayout.vue");
const AdminDashboard = () => import("../views/pages/Admin/Dashboard.vue");
const AdminOrders = () => import("../views/pages/Admin/Orders/List.vue");
const AdminOrderDetail = () => import("../views/pages/Admin/Orders/Detail.vue");
const AdminPayments = () => import("../views/pages/Admin/Payments/List.vue");
const AdminPaymentDetail = () =>
  import("../views/pages/Admin/Payments/Detail.vue");

const AdminPaymentMethod = () =>
  import("../views/pages/Admin/PaymentMethods/List.vue");
const CreatePaymentMethod = () =>
  import("../views/pages/Admin/PaymentMethods/Create.vue");
const EditPaymentMethod = () =>
  import("../views/pages/Admin/PaymentMethods/Edit.vue");

const BaseAdmin = () => import("../views/pages/Admin/BaseAdmin.vue");

const AdminDeliveryMethod = () =>
  import("../views/pages/Admin/DeliveryMethods/List.vue");
const BaseAdminUser = () =>
  import("../views/pages/Admin/Users/BaseAdminUser.vue");
const AdminUsers = () => import("../views/pages/Admin/Users/List.vue");
const AdminCreateUser = () => import("../views/pages/Admin/Users/Create.vue");
const AdminEditUser = () => import("../views/pages/Admin/Users/Edit.vue");
const AdminChangeUserPassword = () =>
  import("../views/pages/Admin/Users/ChangePassword.vue");

const BaseAdminProducts = () =>
  import("../views/pages/Admin/Products/BaseAdminProducts.vue");
const AdminProducts = () => import("../views/pages/Admin/Products/List.vue");
const AdminCreateProduct = () =>
  import("../views/pages/Admin/Products/Create.vue");
const AdminUploadProducts = () =>
  import("../views/pages/Admin/Products/Upload.vue");
const AdminEditProduct = () => import("../views/pages/Admin/Products/Edit.vue");

const BaseAdminVouchers = () =>
  import("../views/pages/Admin/Vouchers/BaseAdminVouchers.vue");
const AdminVouchers = () => import("../views/pages/Admin/Vouchers/List.vue");
const AdminCreateVoucher = () =>
  import("../views/pages/Admin/Vouchers/Create.vue");
const AdminEditVoucher = () => import("../views/pages/Admin/Vouchers/Edit.vue");
const AdminVoucherOrders = () =>
  import("../views/pages/Admin/Vouchers/VoucherOrders.vue");

const BaseAdminSettings = () =>
  import("../views/pages/Admin/Settings/BaseAdminSettings.vue");
const AdminSettings = () => import("../views/pages/Admin/Settings/List.vue");
const AdminCreateSetting = () =>
  import("../views/pages/Admin/Settings/Create.vue");
const AdminEditSetting = () => import("../views/pages/Admin/Settings/Edit.vue");

const BaseAdminCurrencies = () =>
  import("../views/pages/Admin/Currencies/BaseAdminCurrency");
const AdminCurrencies = () =>
  import("../views/pages/Admin/Currencies/List.vue");
const AdminCreateCurrency = () =>
  import("../views/pages/Admin/Currencies/Create.vue");
const AdminEditCurrency = () =>
  import("../views/pages/Admin/Currencies/Edit.vue");

const AdminCategories = () =>
  import("../views/pages/Admin/Categories/List.vue");
const AdminShops = () => import("../views/pages/Admin/Shops/List.vue");
const AdminCreateShop = () => import("../views/pages/Admin/Shops/Create.vue");
const AdminEditShop = () => import("../views/pages/Admin/Shops/Edit.vue");

const AdminRunners = () => import("../views/pages/Admin/Runner/List.vue");
const AdminCreateRunner = () =>
  import("../views/pages/Admin/Runner/Create.vue");
const AdminEditRunner = () => import("../views/pages/Admin/Runner/Edit.vue");
const AdminRunnersRequests = () =>
  import("../views/pages/Admin/Runner/Requests/List.vue");
const AdminRunnersCategories = () =>
  import("../views/pages/Admin/Runner/Categories/List.vue");
const AdminCreateRunnerCategory = () =>
  import("../views/pages/Admin/Runner/Categories/Create.vue");
const AdminEditRunnerCategory = () =>
  import("../views/pages/Admin/Runner/Categories/Edit.vue");
const AdminRunnersRunners = () =>
  import("../views/pages/Admin/Runner/AdminRunnersRunners.vue");

const MerchantRegister = () => import("../views/pages/MerchantRegister.vue");
const MerchantLayout = () =>
  import("../views/pages/Layouts/MerchantLayout.vue");
const MerchantDashboard = () => import("../views/pages/Merchant/Dashboard.vue");
const MerchantDetails = () => import("../views/pages/Merchant/Details.vue");
const MerchantOrders = () => import("../views/pages/Merchant/Orders/List.vue");

const BaseMerchantVouchers = () =>
  import("../views/pages/Merchant/Vouchers/BaseMerchantVouchers.vue");
const MerchantVouchers = () =>
  import("../views/pages/Merchant/Vouchers/List.vue");
const CreateMerchantVoucher = () =>
  import("../views/pages/Merchant/Vouchers/Create.vue");
const EditMerchantVoucher = () =>
  import("../views/pages/Merchant/Vouchers/Edit.vue");

const BaseMerchantProducts = () =>
  import("../views/pages/Merchant/Products/BaseMerchantProducts.vue");
const MerchantProducts = () =>
  import("../views/pages/Merchant/Products/List.vue");
const CreateMerchantProducts = () =>
  import("../views/pages/Merchant/Products/Create.vue");
const EditMerchantProducts = () =>
  import("../views/pages/Merchant/Products/Edit.vue");
const MerchantSettings = () => import("../views/pages/Merchant/Setting.vue");
const MerchantAddresses = () =>
  import("../views/pages/Merchant/Address/List.vue");
const MerchantCreateAddress = () =>
  import("../views/pages/Merchant/Address/Create.vue");
const MerchantEditAddress = () =>
  import("../views/pages/Merchant/Address/Edit.vue");
const MerchantPrescriptions = () =>
  import("../views/pages/Merchant/Prescriptions/List.vue");
const BaseMerchantPrescription = () =>
  import("../views/pages/Merchant/Prescriptions/BasePrescription.vue");
const MerchantCreatePrescription = () =>
  import("../views/pages/Merchant/Prescriptions/Create.vue");
const MerchantEditPrescription = () =>
  import("../views/pages/Merchant/Prescriptions/Edit.vue");
const BasePrescriptionOrder = () =>
  import(
    "../views/pages/Merchant/Prescriptions/PrescriptionOrder/BasePrescriptionOrder.vue"
  );
const MerchantPrescriptionOrders = () =>
  import("../views/pages/Merchant/Prescriptions/PrescriptionOrder/List.vue");
const MerchantCreatePrescriptionOrder = () =>
  import("../views/pages/Merchant/Prescriptions/PrescriptionOrder/Create.vue");
const MerchantEditPrescriptionEditOrder = () =>
  import("../views/pages/Merchant/Prescriptions/PrescriptionOrder/Edit.vue");
const MerchantPrescriptionDetail = () =>
  import("../views/pages/Merchant/Prescriptions/PrescriptionOrder/Detail.vue");
const BasePrescriptionRequest = () =>
  import(
    "../views/pages/Merchant/Prescriptions/PrescriptionRequest/BasePrescriptionRequest.vue"
  );
const MerchantPrescriptionRequests = () =>
  import("../views/pages/Merchant/Prescriptions/PrescriptionRequest/List.vue");
const BaseMerchantHours = () =>
  import("../views/pages/Merchant/Hours/BaseShopHours.vue");
const MerchantHours = () => import("../views/pages/Merchant/Hours/List.vue");
const MerchantCreateHours = () =>
  import("../views/pages/Merchant/Hours/Create.vue");
const MerchantEditHours = () =>
  import("../views/pages/Merchant/Hours/Edit.vue");

const PublicBase = () => import("../views/pages/Layouts/PublicLayout.vue");
const BaseUserProfile = () => import("../views/pages/User/BaseUserProfile.vue");

const BasePrescriptionMedication = () =>
  import("../views/pages/Prescriptions/BasePrescriptionMedication.vue");
const PrescriptionList = () =>
  import("../views/pages/Prescriptions/ListPrescriptionMedication.vue");
const CreatePrescriptionMedication = () =>
  import(
    "../views/pages/Prescriptions/CreatePrescriptionMedicationRequest.vue"
  );

const BaseProfilePrescription = () =>
  import("../views/pages/Profile/Prescriptions/BaseProfilePrescription.vue");
const PrescriptionOrders = () =>
  import("../views/pages/Profile/Prescriptions/List.vue");
const PrescriptionOrder = () =>
  import("../views/pages/Profile/Prescriptions/Order.vue");

const UserOrders = () => import("../views/pages/Order/Orders.vue");
const UserOrder = () => import("../views/pages/Order/Order.vue");
const UserInformation = () => import("../views/pages/User/UserInformation.vue");
const Notifications = () => import("../views/pages/User/Notifications.vue");
const UserAddresses = () => import("../views/pages/User/Address.vue");
const BaseAddress = () =>
  import("../views/pages/Profile/Address/BaseAddress.vue");
const Addresses = () => import("../views/pages/Profile/Address/Index.vue");
const CreateAddress = () => import("../views/pages/Profile/Address/Create.vue");
const EditAddress = () => import("../views/pages/Profile/Address/Edit.vue");

const Shops = () => import("../views/pages/Shops.vue");
const PahukamaStores = () => import("../views/pages/PahukamaStores.vue");
const ShopProduct = () => import("../views/pages/ShopProduct.vue");

const TreatsPlus = () => import("../views/pages/TreatsPlus.vue");

const ForHim = () => import("../views/pages/ForHim.vue");

const ForHer = () => import("../views/pages/ForHer.vue");

const ShopCategoryProduct = () =>
  import("../views/pages/ShopCategoryProduct.vue");
const Cart = () => import("../views/pages/Checkout/Cart.vue");
const Checkout = () => import("../views/pages/Checkout/Index.vue");
const Express = () => import("../views/pages/Checkout/Express/Express.vue");
const Payment = () => import("../views/pages/Checkout/Payment/Payment.vue");
const ProductsResult = () => import("../views/pages/search/ProductsResult.vue");
const ShopsResult = () => import("../views/pages/search/ShopsResult.vue");
const Shop = () => import("../views/pages/Shop.vue");
const ShopCategories = () => import("../views/pages/ShopCategories.vue");
const Auth = () => import("../views/pages/Auth.vue");
const Verify = () => import("../views/pages/Auth/Verify.vue");
const Product = () => import("../views/pages/Product.vue");
const WaitingShop = () => import("../views/pages/WaitingShop.vue");
const BaseAuth = () => import("../views/pages/Auth/BaseAuth");
const Login = () => import("../views/pages/Auth/Login.vue");
const Register = () => import("../views/pages/Auth/Register.vue");
const ForgotPassword = () => import("../views/pages/Auth/ForgotPassword.vue");
const PaymentFailed = () =>
  import("../views/pages/Checkout/Payment/PaymentFailed");
const PaymentSuccessful = () =>
  import("../views/pages/Checkout/Payment/PaymentSuccessful");
const Promotions = () => import("../views/pages/Promotions.vue");

const BaseBuyVoucher = () =>
  import("../views/pages/Vouchers/BaseBuyVoucher.vue");
const BuyVoucher = () => import("../views/pages/Vouchers/BuyVoucher.vue");

const VoucherDetail = () => import("../views/pages/Vouchers/VoucherDetail.vue");
const Vouchers = () => import("../views/pages/Vouchers/Vouchers.vue");

const BaseProfileVouchers = () =>
  import("../views/pages/Profile/Vouchers/BaseProfileVouchers.vue");
const UserVouchers = () =>
  import("../views/pages/Profile/Vouchers/Vouchers.vue");
const UserVoucher = () => import("../views/pages/Profile/Vouchers/Voucher.vue");

const TopUp = () => import("../views/pages/TopUp/TopUp.vue");

const BaseListing = () => import("../views/pages/Listings/BaseListing.vue");
const Listings = () => import("../views/pages/Listings/Listings.vue");
const Listing = () => import("../views/pages/Listings/Listing.vue");
const PahukamaBnB = () => import("../views/pages/Listings/PahukamaBnB.vue");

const BaseProfileListing = () =>
  import("../views/pages/Profile/Listings/BaseListing.vue");
const MyListings = () => import("../views/pages/Profile/Listings/List.vue");
const CreateListing = () =>
  import("../views/pages/Profile/Listings/Create.vue");
const EditListing = () => import("../views/pages/Profile/Listings/Edit.vue");
const ListingDetail = () => import("../views/pages/Profile/Listings/Detail");

const HowTo = () => import("../views/pages/HowTo.vue");
const PickupDelivery = () => import("../views/pages/PickupDelivery.vue");
const PrivacyPolicy = () => import("../views/pages/PrivacyPolicy.vue");
const AboutUs = () => import("../views/pages/AboutUs.vue");

const DialFrom263 = () => import("../views/pages/DialFrom263/DialFrom263.vue");
const BaseAirtimePurchase = () =>
  import("../views/pages/Profile/AirtimePurchase/BaseAirtimePurchase.vue");
const AirtimePurchases = () =>
  import("../views/pages/Profile/AirtimePurchase/List.vue");
const BaseDialFrom263 = () =>
  import("../views/pages/DialFrom263/BaseDialFrom263.vue");

const SimplePayDirect = () =>
  import("../views/pages/SimplePayDirect/SimplePayDirect.vue");

const Runner1 = () => import("../views/pages/Runner/Runner.vue");
const RunnerRequest = () => import("../views/pages/Runner/RunnerRequest.vue");
const RunnerRequests1 = () =>
  import("../views/pages/Runner/RunnerRequests.vue");
const BaseRunner1 = () => import("../views/pages/Runner/BaseRunner.vue");
const EditRunnerRequest1 = () =>
  import("../views/pages/Runner/EditRunnerRequest.vue");
const MyRunnerRequests1 = () =>
  import("../views/pages/Runner/MyRunnerRequests.vue");
const RegisterRunner = () => import("../views/pages/Runner/RegisterRunner.vue");

const Runner = () => import("../views/pages/RunnerRequests/Create.vue");
const BaseRunnerRequest = () =>
  import("../views/pages/RunnerRequests/BaseRunnerRequest");
const CreateRunnerRequest = () =>
  import("../views/pages/RunnerRequests/Create.vue");
const BaseProfileRunnerRequest = () =>
  import("../views/pages/Profile/RunnerRequests/BaseRunnerRequest.vue");
const RunnerRequests = () =>
  import("../views/pages/Profile/RunnerRequests/List.vue");
const EditRunnerRequest = () =>
  import("../views/pages/Profile/RunnerRequests/Edit.vue");

const Specialist = () => import("../views/pages/Specialist/Specialist.vue");
const SpecialistRequest = () =>
  import("../views/pages/Specialist/SpecialistRequest.vue");
const SpecialistRequests = () =>
  import("../views/pages/Specialist/SpecialistRequests.vue");
const BaseSpecialist = () =>
  import("../views/pages/Specialist/BaseSpecialist.vue");
const EditSpecialistRequest = () =>
  import("../views/pages/Specialist/EditSpecialistRequest.vue");
const MySpecialistRequests = () =>
  import("../views/pages/Specialist/MySpecialistRequests.vue");
const RegisterSpecialist = () =>
  import("../views/pages/Specialist/RegisterSpecialist.vue");

const RequestQuotation = () =>
  import("../views/pages/RequestQuotation/RequestQuotation.vue");
const SpecialEvents = () => import("../views/pages/SpecialEvents.vue");
const ResetPassword = () => import("../views/pages/Auth/ResetPassword.vue");

Vue.use(VueRouter);

const routes = [
  { path: "/not-home", name: "landing", component: LandingPage },
  {
    path: "/",
    component: PublicBase,
    children: [
      { path: "", name: "home", component: Desktop },
      { path: "/mobile", name: "mobile", component: HomeStoreFront },
      // { path: "/desktop", name: "desktop", component: Desktop },
      {
        path: "home-store-front",
        name: "home_store_front",
        component: Home,
      },
      { path: "", name: "home", component: HomeStoreFront2 },
      {
        path: "home-store-front",
        name: "home_store_front",
        component: Home,
      },
      {
        path: "/services",
        name: "Services",
        component: Services,
      },

      {
        path: "shop-now",
        name: "shop-now",
        component: HomeShopNow,
      },
      {
        path: "butchery",
        name: "butchery",
        component: HomeButchery,
      },
      {
        path: "/pickup-delivery",
        name: "pickup_delivery",
        component: PickupDelivery,
      },
      {
        path: "/privacy-policy",
        name: "privacy_policy",
        component: PrivacyPolicy,
      },
      {
        path: "/about-us",
        name: "about_us",
        component: AboutUs,
      },
      { path: "/promotions", name: "promotions", component: Promotions },
      {
        path: "/vouchers",
        component: BaseBuyVoucher,
        children: [
          { path: "", name: "vouchers", component: Vouchers },
          { path: "buy", name: "buy_voucher", component: Vouchers },
          { path: ":slug", name: "voucher_detail", component: VoucherDetail },
        ],
      },
      { path: "/smart-topup", name: "topup_now", component: TopUp },
      {
        path: "runners",
        component: BaseRunner1,
        children: [
          {
            path: "runner_requests",
            name: "runner_requests",
            component: Runner1,
          },
          {
            path: "requests/view/:id",
            name: "runner_request",
            component: RunnerRequest,
          },
          {
            path: "requests/:status",
            name: "filtered_runner_requests",
            component: RunnerRequests1,
          },
          {
            path: "requests/edit/:id",
            name: "edit_runner_requests_old",
            component: EditRunnerRequest1,
          },
          {
            path: "my-requests",
            name: "my_runner_requests_old",
            component: MyRunnerRequests1,
          },
        ],
      },

      {
        path: "specialist",
        component: BaseSpecialist,
        children: [
          {
            path: "/specialists-jobs-tasks",
            name: "specialist_requests",
            component: Specialist,
          },
          {
            path: "requests/view/:id",
            name: "specialist_request",
            component: SpecialistRequest,
          },
          {
            path: "requests/:status",
            name: "filtered_specialist_requests",
            component: SpecialistRequests,
          },
          {
            path: "requests/edit/:id",
            name: "edit_specialist_requests",
            component: EditSpecialistRequest,
          },
          {
            path: "my-requests",
            name: "my_specialist_requests",
            component: MySpecialistRequests,
          },
        ],
      },

      {
        path: "/dialfrom263",
        component: BaseDialFrom263,
        children: [{ path: "", component: DialFrom263, name: "dial_from_263" }],
      },
      {
        path: "/simple_pay_direct",
        name: "simple_pay_direct",
        component: SimplePayDirect,
      },
      {
        path: "/become-a-runner",
        name: "be_a_runner",
        component: RegisterRunner,
      },
      {
        path: "/listings/category",
        component: BaseListing,
        children: [
          { path: "view/:id", name: "listing", component: Listing },
          {
            path: "category/:category_id?",
            name: "listings",
            component: Listings,
          },
          {
            path: "pahukama-bnb/",
            name: "listings.pahukama.bnb",
            component: PahukamaBnB,
          },
        ],
      },
      { path: "/how-to", name: "how_to", component: HowTo },
      {
        path: "/request-quotation",
        name: "request_quotation",
        component: RequestQuotation,
      },
      {
        path: "/special-events-treats",
        name: "special-events",
        component: SpecialEvents,
      },
      { path: "/waiting-shop", name: "waiting_shop", component: WaitingShop },
      { path: "/product/:slug", name: "Product", component: Product },
      {
        path: "/merchant-register",
        name: "merchant_register",
        component: MerchantRegister,
      },
      { path: "/shop-category/:slug", name: "Shops", component: Shops },
      {
        path: "/shops-products/:id",
        name: "ShopProduct",
        component: ShopProduct,
      },
      {
        path: "/products/:slug",
        name: "category_products",
        component: ShopCategoryProduct,
      },
      { path: "/products", name: "shop_products", component: ShopProduct },

      { path: "/treats-plus", name: "treats-plus", component: TreatsPlus },
      { path: "/for_him", name: "for_him", component: ForHim },
      { path: "/for_her", name: "for_her", component: ForHer },
      { path: "/shop/:slug", name: "shop", component: Shop },
      { path: "/shop", name: "pahukama-stores", component: PahukamaStores },
      {
        path: "/shop-categories",
        name: "shop_categories",
        component: ShopCategories,
      },
      {
        path: "/search-products-result/:query",
        name: "products_result",
        component: ProductsResult,
      },
      {
        path: "/search-shops-result/:query/:type",
        name: "ShopsResult",
        component: ShopsResult,
      },
      { path: "/verify", component: Verify, name: "verify_phone_number" },
      { path: "/cart", name: "cart", component: Cart },
      {
        path: "/checkout",
        name: "checkout",
        component: Checkout,
        meta: { middleware: [auth] },
      },
      {
        path: "/express/:id",
        name: "express",
        component: Express,
        meta: { middleware: [auth] },
      },
      {
        path: "/payment/:id",
        name: "payment",
        component: Payment,
        meta: { middleware: [auth] },
      },
      {
        path: "payment/:id/failed",
        component: PaymentFailed,
        name: "payment_failed",
        meta: { middleware: [auth] },
      },
      {
        path: "payment/:id/success",
        component: PaymentSuccessful,
        name: "payment_successful",
        meta: { middleware: [auth] },
      },
      {
        path: "",
        component: BaseAuth,
        children: [
          { path: "login", component: Login, name: "login" },
          { path: "register", component: Register, name: "register" },
        ],
      },
      { path: "/forgot", name: "forgot_password", component: ForgotPassword },
      {
        path: "/reset-password",
        name: "reset_password",
        component: ResetPassword,
      },
      {
        path: "/prescriptions",
        component: BasePrescriptionMedication,
        meta: { middleware: [auth] },
        children: [
          {
            path: "",
            component: PrescriptionList,
            name: "prescriptions",
            // meta: { middleware: [auth] },
          },
          {
            path: "create",
            component: CreatePrescriptionMedication,
            name: "create_prescription_request",
            meta: { middleware: [auth] },
          },
        ],
      },
      {
        path: "/my",
        component: BaseUserProfile,
        meta: { middleware: [auth] },
        children: [
          {
            path: "addresses",
            component: BaseAddress,
            children: [
              {
                path: "",
                component: Addresses,
                name: "my_addresses",
                meta: { middleware: [auth] },
              },
              {
                path: ":address_type/create",
                component: CreateAddress,
                name: "create_address",
                meta: { middleware: [auth] },
              },
              {
                path: ":id/edit",
                component: EditAddress,
                name: "edit_address",
                meta: { middleware: [auth] },
              },
            ],
          },
          {
            path: "prescriptions",
            component: BaseProfilePrescription,
            children: [
              {
                path: "",
                component: PrescriptionOrders,
                name: "my_prescriptions",
                meta: { middleware: [auth] },
              },
              {
                path: "create",
                component: PrescriptionOrder,
                name: "create_prescription_order",
                meta: { middleware: [auth] },
              },
            ],
          },
          {
            path: "orders",
            component: BaseProfilePrescription,
            children: [
              {
                path: "",
                component: UserOrders,
                name: "my_orders",
                meta: { middleware: [auth] },
              },
              {
                path: ":id",
                component: UserOrder,
                name: "my_order",
                meta: { middleware: [auth] },
              },
            ],
          },
          {
            path: "vouchers",
            component: BaseProfileVouchers,
            children: [
              {
                path: "",
                component: UserVouchers,
                name: "my_vouchers",
                meta: { middleware: [auth] },
              },
              {
                path: ":id",
                component: UserVoucher,
                name: "my_voucher",
                meta: { middleware: [auth] },
              },
            ],
          },
          {
            path: "listings",
            component: BaseProfileListing,
            children: [
              { path: "", name: "my_listings", component: MyListings },
              {
                path: "edit/:id",
                name: "edit_listing",
                component: EditListing,
              },
              {
                path: "create",
                name: "create_listing",
                component: CreateListing,
              },
              {
                path: ":id/detail",
                component: ListingDetail,
                name: "my_listing_detail",
                meta: { middleware: [auth] },
              },
            ],
          },
          {
            path: "airtime-purchases",
            component: BaseAirtimePurchase,
            children: [
              {
                path: "",
                component: AirtimePurchases,
                name: "my_airtime_purchases",
              },
              {
                path: ":id",
                component: Listing,
                name: "my_airtime_purchase",
                meta: { middleware: [auth] },
              },
            ],
          },
          {
            path: "runner-requests",
            component: BaseProfileRunnerRequest,
            children: [
              {
                path: "",
                name: "my_runner_requests",
                component: RunnerRequests,
              },
              {
                path: "edit/:id",
                name: "edit_runner_requests",
                component: EditRunnerRequest,
              },
              {
                path: "create",
                name: "create_runner_requests",
                component: CreateRunnerRequest,
              },
            ],
          },
          {
            path: "profile",
            component: UserInformation,
            name: "my_profile",
            meta: { middleware: [auth] },
          },
          {
            path: "notifications",
            component: Notifications,
            name: "notifications",
            meta: { middleware: [auth] },
          },
        ],
      },
    ],
  },
  {
    path: "/merchant",
    component: MerchantLayout,
    meta: { middleware: [auth] },
    children: [
      {
        path: "",
        component: MerchantDashboard,
        name: "merchant_dashboard",
        meta: { middleware: [auth] },
      },
      {
        path: "details",
        component: MerchantDetails,
        name: "merchant_details",
        meta: { middleware: [auth] },
      },
      {
        path: "orders",
        component: MerchantOrders,
        name: "merchant_orders",
        meta: { middleware: [auth] },
      },
      {
        path: "shop-addresses",
        component: MerchantAddresses,
        name: "merchant_addresses",
        meta: { middleware: [auth] },
      },
      {
        path: "create-address",
        component: MerchantCreateAddress,
        name: "merchant_create_address",
        meta: { middleware: [auth] },
      },
      {
        path: ":id/edit-address",
        component: MerchantEditAddress,
        name: "merchant_edit_address",
        meta: { middleware: [auth] },
      },
      {
        path: "vouchers",
        component: BaseMerchantVouchers,
        children: [
          {
            path: "",
            component: MerchantVouchers,
            name: "merchant_vouchers",
            meta: { middleware: [auth] },
          },
          {
            path: "create",
            component: CreateMerchantVoucher,
            name: "merchant_create_voucher",
            meta: { middleware: [auth] },
          },
          {
            path: ":id/edit",
            component: EditMerchantVoucher,
            name: "merchant_edit_voucher",
            meta: { middleware: [auth] },
          },
        ],
      },

      {
        path: "hours",
        component: BaseMerchantHours,
        children: [
          {
            path: "",
            component: MerchantHours,
            name: "merchant_hours",
            meta: { middleware: [auth] },
          },
          {
            path: "create",
            component: MerchantCreateHours,
            name: "merchant_create_hours",
            meta: { middleware: [auth] },
          },
          {
            path: ":id/edit",
            component: MerchantEditHours,
            name: "merchant_edit_hours",
            meta: { middleware: [auth] },
          },
        ],
      },

      {
        path: "products",
        component: BaseMerchantProducts,
        children: [
          {
            path: "",
            component: MerchantProducts,
            name: "merchant_products",
            meta: { middleware: [auth] },
          },
          {
            path: "create",
            component: CreateMerchantProducts,
            name: "merchant_create_products",
            meta: { middleware: [auth] },
          },
          {
            path: ":id/edit",
            component: EditMerchantProducts,
            name: "merchant_edit_products",
            meta: { middleware: [auth] },
          },
        ],
      },
      {
        path: "prescriptions",
        component: BaseMerchantPrescription,
        children: [
          {
            path: "",
            component: MerchantPrescriptions,
            name: "merchant_prescriptions",
            meta: { middleware: [auth] },
          },
          {
            path: "create",
            component: MerchantCreatePrescription,
            name: "merchant_create_prescriptions",
            meta: { middleware: [auth] },
          },
          {
            path: ":id/edit",
            component: MerchantEditPrescription,
            name: "merchant_edit_prescriptions",
            meta: { middleware: [auth] },
          },
          {
            path: "orders",
            component: BasePrescriptionOrder,
            children: [
              {
                path: "",
                component: MerchantPrescriptionOrders,
                name: "merchant_prescription_orders",
                meta: { middleware: [auth] },
              },
              {
                path: "create",
                component: MerchantCreatePrescriptionOrder,
                name: "merchant_create_prescription_order",
                meta: { middleware: [auth] },
              },
              {
                path: ":id/edit",
                component: MerchantEditPrescriptionEditOrder,
                name: "merchant_edit_prescription_order",
                meta: { middleware: [auth] },
              },
              {
                path: ":id/detail",
                component: MerchantPrescriptionDetail,
                name: "merchant_prescription_order_detail",
                meta: { middleware: [auth] },
              },
            ],
          },
          {
            path: "requests",
            component: BasePrescriptionRequest,
            children: [
              {
                path: "",
                component: MerchantPrescriptionRequests,
                name: "merchant_prescription_requests",
                meta: { middleware: [auth] },
              },
              {
                path: ":id/detail",
                component: MerchantPrescriptionDetail,
                name: "merchant_prescription_request_detail",
                meta: { middleware: [auth] },
              },
            ],
          },
        ],
      },
      {
        path: "settings",
        component: MerchantSettings,
        name: "merchant_settings",
        meta: { middleware: [auth] },
      },
    ],
  },
  {
    path: "/admin",
    component: AdminLayout,
    meta: { middleware: [auth] },
    children: [
      {
        path: "",
        component: AdminDashboard,
        name: "admin_dashboard",
        meta: { middleware: [auth] },
      },
      {
        path: "details",
        component: MerchantDetails,
        name: "admin_details",
        meta: { middleware: [auth] },
      },
      {
        path: "shops",
        component: BaseAdmin,
        children: [
          {
            path: "",
            component: AdminShops,
            name: "admin_shops",
            meta: { middleware: [auth] },
          },
          {
            path: "create",
            component: AdminCreateShop,
            name: "admin_create_shop",
            meta: { middleware: [auth] },
          },
          {
            path: ":id/edit",
            component: AdminEditShop,
            name: "admin_edit_shop",
            meta: { middleware: [auth] },
          },
        ],
      },
      {
        path: "products",
        component: BaseAdminProducts,
        children: [
          {
            path: "",
            component: AdminProducts,
            name: "admin_products",
            meta: { middleware: [auth] },
          },
          {
            path: "create",
            component: AdminCreateProduct,
            name: "admin_create_product",
            meta: { middleware: [auth] },
          },
          {
            path: "upload",
            component: AdminUploadProducts,
            name: "admin_upload_products",
            meta: { middleware: [auth] },
          },
          {
            path: ":id/edit",
            component: AdminEditProduct,
            name: "admin_edit_product",
            meta: { middleware: [auth] },
          },
        ],
      },
      {
        path: "vouchers",
        component: BaseAdminVouchers,
        children: [
          {
            path: "",
            component: AdminVouchers,
            name: "admin_vouchers",
            meta: { middleware: [auth] },
          },
          {
            path: "create",
            component: AdminCreateVoucher,
            name: "admin_create_voucher",
            meta: { middleware: [auth] },
          },
          {
            path: ":id/edit",
            component: AdminEditVoucher,
            name: "admin_edit_voucher",
            meta: { middleware: [auth] },
          },
          {
            path: ":id/delete",
            component: AdminEditVoucher,
            name: "admin_delete_voucher",
            meta: { middleware: [auth] },
          },
          {
            path: "orders",
            component: AdminVoucherOrders,
            name: "admin_voucher_orders",
            meta: { middleware: [auth] },
          },
        ],
      },
      {
        path: "orders",
        component: BasePrescriptionOrder,
        children: [
          {
            path: "",
            component: AdminOrders,
            name: "admin_orders",
            meta: { middleware: [auth] },
          },
          {
            path: ":id/detail",
            component: AdminOrderDetail,
            name: "admin_order_detail",
            meta: { middleware: [auth] },
          },
        ],
      },
      {
        path: "users",
        component: BaseAdminUser,
        children: [
          {
            path: "",
            component: AdminUsers,
            name: "admin_users",
            meta: { middleware: [auth] },
          },
          {
            path: "create",
            component: AdminCreateUser,
            name: "admin_create_user",
            meta: { middleware: [auth] },
          },
          {
            path: ":id/edit",
            component: AdminEditUser,
            name: "admin_edit_user",
            meta: { middleware: [auth] },
          },
          {
            path: ":id/password-change",
            component: AdminChangeUserPassword,
            name: "admin_change_user_password",
            meta: { middleware: [auth] },
          },
          {
            path: ":id/detail",
            component: AdminUsers,
            name: "admin_user_detail",
            meta: { middleware: [auth] },
          },
        ],
      },
      {
        path: "payments",
        component: BasePrescriptionOrder,
        children: [
          {
            path: "",
            component: AdminPayments,
            name: "admin_payments",
            meta: { middleware: [auth] },
          },
          {
            path: ":id/detail",
            component: AdminPaymentDetail,
            name: "admin_payment_detail",
            meta: { middleware: [auth] },
          },
        ],
      },
      {
        path: "payment-methods",
        component: BasePrescriptionOrder,
        children: [
          {
            path: "",
            component: AdminPaymentMethod,
            name: "admin_payment_methods",
            meta: { middleware: [auth] },
          },
          {
            path: "create",
            component: CreatePaymentMethod,
            name: "admin_create_payment_method",
            meta: { middleware: [auth] },
          },
          {
            path: ":id/edit",
            component: EditPaymentMethod,
            name: "admin_edit_payment_method",
            meta: { middleware: [auth] },
          },
          {
            path: ":id/detail",
            component: AdminPaymentMethod,
            name: "admin_payment_method_detail",
            meta: { middleware: [auth] },
          },
        ],
      },
      {
        path: "delivery-methods",
        component: BasePrescriptionOrder,
        children: [
          {
            path: "",
            component: AdminDeliveryMethod,
            name: "admin_delivery_methods",
            meta: { middleware: [auth] },
          },
          {
            path: "create",
            component: AdminDeliveryMethod,
            name: "admin_create_delivery_method",
            meta: { middleware: [auth] },
          },
          {
            path: ":id/edit",
            component: AdminDeliveryMethod,
            name: "admin_edit_delivery_method",
            meta: { middleware: [auth] },
          },
          {
            path: ":id/detail",
            component: AdminDeliveryMethod,
            name: "admin_delivery_method_detail",
            meta: { middleware: [auth] },
          },
        ],
      },
      {
        path: "currencies",
        component: BaseAdminCurrencies,
        children: [
          {
            path: "",
            component: AdminCurrencies,
            name: "admin_currencies",
            meta: { middleware: [auth] },
          },
          {
            path: "create",
            component: AdminCreateCurrency,
            name: "admin_create_currency",
            meta: { middleware: [auth] },
          },
          {
            path: ":id/edit",
            component: AdminEditCurrency,
            name: "admin_edit_currency",
            meta: { middleware: [auth] },
          },
        ],
      },
      {
        path: "settings",
        component: BaseAdminSettings,
        children: [
          {
            path: "",
            component: AdminSettings,
            name: "admin_settings",
            meta: { middleware: [auth] },
          },
          {
            path: "create",
            component: AdminCreateSetting,
            name: "admin_create_setting",
            meta: { middleware: [auth] },
          },
          {
            path: ":id/edit",
            component: AdminEditSetting,
            name: "admin_edit_setting",
            meta: { middleware: [auth] },
          },
        ],
      },
      {
        path: "runners",
        component: BaseAdminSettings,
        children: [
          {
            path: "",
            component: AdminRunners,
            name: "admin_runners",
            meta: { middleware: [auth] },
          },
          {
            path: "requests",
            component: AdminRunnersRequests,
            name: "admin_runners_requests",
            meta: { middleware: [auth] },
          },
          {
            path: "create",
            component: AdminCreateRunner,
            name: "admin_create_runner",
            meta: { middleware: [auth] },
          },
          {
            path: ":id/edit",
            component: AdminEditRunner,
            name: "admin_edit_runner",
            meta: { middleware: [auth] },
          },
          {
            path: "categories",
            component: AdminRunnersCategories,
            name: "admin_runners_categories",
            meta: { middleware: [auth] },
          },
          {
            path: "categories/create",
            component: AdminCreateRunnerCategory,
            name: "admin_create_runner_category",
            meta: { middleware: [auth] },
          },
          {
            path: "categories/:id/edit",
            component: AdminEditRunnerCategory,
            name: "admin_edit_runner_category",
            meta: { middleware: [auth] },
          },
        ],
      },
      {
        path: "admin_runners/runners",
        component: AdminRunnersRunners,
        name: "admin_runners_runners",
        meta: { middleware: [auth] },
      },
    ],
  },
  { path: "*", redirect: { path: "/" } },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,

  routes,
  scrollBehavior(to, from, savedPosition) {
    // Scroll to the top of the page on route change or refresh
    if (savedPosition) {
      return savedPosition;
    } 
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  //window.scrollTo(0, 0);
  if (!to.meta.middleware) {
    return next();
  }

  const middleware = to.meta.middleware;

  const context = {
    to,
    from,
    next,
  };
  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});
export default router;
