import Vue from 'vue';
import Vuex from 'vuex';
import cart from './modules/cart';
import auth from './modules/auth';
import shop from './modules/shop';
import product from './modules/product';
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        cart: cart,
        auth: auth,
        shop: shop,
        product: product
    },
    plugins: [createPersistedState()],
});

