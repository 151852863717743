import Vue from 'vue';
import Vuex from 'vuex';
import axios from "axios";

const CART_STORAGE_KEY = 'cart';
const isProduction = process.env.NODE_ENV === 'production';


Vue.use(Vuex);

const state = {
    cartItems: [],
    total: 0,
    cartCount: 0,
    shopId: 0
};

const getters = {
    cart: (state) => {
        return state.cart;
    },
    permissions(state) {
        return state.permissions;
    },
    isAuthenticated: state => !!state.token,
};

const actions = {
    addToCart({ commit, state }, { product, quantity }) {
        const cartItem = state.cartItems.find(item => item?.id === product.id);

        if (cartItem) {
            const updatedQuantity = quantity + parseInt(cartItem.quantity);
            const totalPrice = (updatedQuantity * parseFloat(cartItem.price)) + parseFloat(cartItem.props?.fees || 0.00);
            // console.log("total", totalPrice);

            state.cartItems = state.cartItems.map(item =>
                item.id === product.id ? { ...cartItem, quantity: updatedQuantity, total: totalPrice } : item
            );
        } else {
            const newCartItem = {
                id: product.id,
                title: product.name,
                type: product.type || 'product',
                quantity,
                price: product.price,
                total: product.props?.fees ? parseFloat(product.total) : parseFloat(product.price * quantity),
                shop: product.shop,
                phone: product.phone,
                props: product.props || {},
                service: product.service || '',
                company_name: product.company_name || '',
                account_number: product.account_number || '',
                account_name: product.account_name || '',
                payment_type: product.type || '',
                currency: product.currency || '',
            };

            state.shopId = newCartItem.shop.id || newCartItem.shop;
            commit('addToCart', newCartItem);
        }

        // console.log('addToCart');
        // console.log(cartItem);
        // console.log(product);
        commit('updateTotalAmount');
        commit('updateTotalQuantity', quantity);
        commit('saveCart');
    },

    addDataBundle({ commit, state }, { product, quantity }) {
        let cartItem = state.cartItems.find(cartItem => cartItem && cartItem.id === product.id)
        if (cartItem) {
            let qty = quantity + parseInt(cartItem.quantity)
            let price = parseFloat(cartItem.price)
            let total = qty * price
            state.cartItems = state.cartItems.map(item => {
                if (item.id === product.id) {
                    return { ...cartItem, quantity: qty, total: total }
                } else {
                    return item;
                }
            })

            commit('saveCart');
            commit('updateTotalAmount');
            commit('updateTotalQuantity', quantity);
        } else {
            cartItem = {
                id: product.id,
                title: product.name,
                quantity: quantity,
                price: product.price,
                total: product.price * quantity,
                shop: product.shop,
                props: product.props ? product.props : {}
            };
            // console.log('addToCart');
            // console.log(cartItem);
            // console.log(product);
            state.shopId = cartItem.shop.id;
            commit('addToCart', cartItem);
            commit('updateTotalAmount');
            commit('updateTotalQuantity', quantity);
            commit('saveCart');
        }
    },

    addTelkomBundle({ commit, state }, { product, quantity }) {
        let cartItem = state.cartItems.find(cartItem => cartItem && cartItem.id === product.id)
        if (cartItem) {
            let qty = quantity + parseInt(cartItem.quantity)
            let price = parseFloat(cartItem.price)
            let total = qty * price
            state.cartItems = state.cartItems.map(item => {
                if (item.id === product.id) {
                    return { ...cartItem, quantity: qty, total: total }
                } else {
                    return item;
                }
            })

            commit('saveCart');
            commit('updateTotalAmount');
            commit('updateTotalQuantity', quantity);
        } else {
            cartItem = {
                id: product.id,
                title: product.name,
                quantity: quantity,
                price: product.price,
                total: product.price * quantity,
                shop: product.shop,
                props: product.props ? product.props : {}
            };
            // console.log('addToCart');
            // console.log(cartItem);
            // console.log(product);
            state.shopId = cartItem.shop.id;
            commit('addToCart', cartItem);
            commit('updateTotalAmount');
            commit('updateTotalQuantity', quantity);
            commit('saveCart');
        }
    },

    clearCart({ commit, state }) {
        commit('clearCart');
        commit('saveCart');
    },

    removeFromCart({ commit, state }, { product, quantity }) {
        const cartItem = state.cartItems.find(item => item.id === product.id);

        if (cartItem) {
            const updatedQuantity = cartItem.quantity - quantity;

            if (updatedQuantity > 0) {
                const updatedCartItem = { ...cartItem, quantity: updatedQuantity, total: (updatedQuantity * parseFloat(cartItem.price)) + parseFloat(cartItem.props?.fees || 0.00) };
                state.cartItems = state.cartItems.map(item => (item.id === product.id ? updatedCartItem : item));
            } else {
                // Remove the item completely
                state.cartItems = state.cartItems.filter(item => item.id !== product.id);
            }

            commit('updateTotalAmount');
            commit('updateTotalQuantity', -quantity);
            commit('saveCart');
        }
    },

    removeCartProduct({ commit, state }, { product }) {
        let cartItem = state.cartItems.find(cartItem => cartItem.id === product.id)
        if (cartItem) {
            let cartItems = [];
            state.cartItems.forEach(item => {
                if (item) {
                    if (item.id !== product.id) {
                        cartItems.push(item);
                    }
                }
            });
            state.cartItems = cartItems;
            commit('updateTotalAmount');
            commit('updateTotalQuantity', 0);
            commit('saveCart');
        }
    },

    // Send a request to the login URL and save the returned JWT
    increase({ commit, dispatch }, { next, credentials, onError }) {
        let form = new FormData();
        Object.keys(credentials).forEach((k) => {
            form.set(k, credentials[k])
        });
    },
};

const mutations = {
    addToCart(state, cartItem) {
        state.cartItems.push(cartItem)
    },
    updateTotalAmount(state) {
        const totalAmount = state.cartItems.reduce((total, item) => {
            return total + (item?.total ?? 0);
        }, 0);
        state.total = totalAmount;
    },
    updateTotalQuantity(state, totalQuantity) {
        let totalQty = 0;
        for (let item of state.cartItems) {
            if (item && item.hasOwnProperty('total')) {
                totalQty += item.quantity;
            }
        }
        state.cartCount = totalQty;
    },
    saveCart(state) {
        localStorage.setItem(CART_STORAGE_KEY, JSON.stringify(state));
    },
    clearCart(state) {
        console.log("Clear cart contents")
        state.cartItems = [];
        state.total = 0;
        state.cartCount = 0;
        state.shopId = 0;

    }
};

export default {
    // We're using namespacing
    // in all of our modules.
    namespaced: true,
    mutations,
    actions,
    getters,
    state,
};

