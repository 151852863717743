// cache.js
const CACHE_PREFIX = "api-cache-";

const setCache = (key, data) => {
  console.log(`Setting cache for key: ${key}`);
  const cacheData = {
    data,
    timestamp: Date.now(),
  };
  // Store data in localStorage with a unique prefix
  localStorage.setItem(CACHE_PREFIX + key, JSON.stringify(cacheData));
};
// set maxAge to 6 hours
const getCache = (key, maxAge = 60 * 60 * 6 * 1000) => {
  const cacheItem = localStorage.getItem(CACHE_PREFIX + key);
  if (!cacheItem) {
    console.log(`No cache found for key: ${key}`);
    return null;
  }

  const cached = JSON.parse(cacheItem);
  if (Date.now() - cached.timestamp < maxAge) {
    console.log(`Cache hit for key: ${key}`);
    return cached.data;
  }

  // If cache is stale, remove it from localStorage
  console.log(`Cache expired for key: ${key}`);
  localStorage.removeItem(CACHE_PREFIX + key);
  return null;
};

export { setCache, getCache };
