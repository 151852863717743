<template
  xmlns="http://www.w3.org/1999/html"
  xmlns="http://www.w3.org/1999/html"
>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "App",
  computed: {
    ...mapState({
      cart: (state) => state.cart,
      auth: (state) => state.auth,
    }),
  },
  mounted() {
    if (this.auth.isMerchant && this.$route.name.substr(0, 8) !== "merchant") {
      this.$router.push({ name: "merchant_dashboard" });
    }
    this.$store.dispatch("shop/getCategories");
    this.$store.dispatch("product/getCategories");
  },
  methods: {},
};
</script>
