import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import logger from "../../logging";
// import lodash from '@lodash';
import router from "@/router";

const TOKEN_STORAGE_KEY = "token";
const isProduction = process.env.NODE_ENV === "production";

Vue.use(Vuex);

const state = {
  isAuthenticated: false,
  isMerchant: false,
  isAdmin: false,
  user: null,
  shop: null,
  role: null,
  token: localStorage.getItem("token"),
  next: null,
  endpoints: {
    // TODO: Remove hardcoding of dev endpoints
    obtainJWT: "/auth/obtain-token/",
    refreshJWT: "/auth/refresh-token/",
    // baseUrl: (isProduction ? 'https://dev-api.pahukama.com/api/' : ' http://127.0.0.1:8000/api/')
    baseUrl: isProduction
      ? process.env.VUE_APP_API_URL
      : " http://127.0.0.1:8000/api/",
  },
};

const getters = {
  user: (state) => {
    return state.user;
  },
  permissions(state) {
    return state.permissions;
  },
  isAuthenticated: (state) => !!state.token,
  isAAdmin: (state) => {
      return this.state.isAdmin
  },
};

const actions = {
  initialize({ commit }) {
    const token = localStorage.getItem(TOKEN_STORAGE_KEY);

    if (isProduction && token) {
      console.log("Remove token");
      // commit('removeToken'); # TODO - Enable this for production
      commit("removeToken"); // I have enabled out #Leo
      commit("setToken", token);
    }

    if (!isProduction && token) {
      console.log("Set token");
      commit("setToken", token);
    }
  },

  nextPage(context, nextPage) {
    context.commit("next", nextPage);
  },

  user(context, user) {
    context.commit("user", user);
  },
  // Send a request to the login URL and save the returned JWT
  login({ commit, dispatch }, { next, credentials, onError }) {
    let form = new FormData();
    Object.keys(credentials).forEach((k) => {
      form.set(k, credentials[k]);
    });
    return axios.post("auth/login/", form).then(
      (response) => {
        logger.default.info("Successfully logged in as", credentials.username);
        // commit('token', response.data.token)
        localStorage.setItem("token", response.data.token);
        // localStorage.setItem('refresh', response.data.refresh);
        axios.defaults.headers["Authorization"] =
          "Bearer " + localStorage.getItem("token");
        dispatch("fetchProfile").then(() => {
          // Redirect to a specified route
          console.log(next);
          router.push(next);
        });
      },
      (response) => {
        logger.default.error("Error while logging in", response.data);
        onError(response);
      }
    );
  },

  async logout({ state, commit }) {
    commit("reset", null);
    commit("setToken", null);
    commit("removeToken", null);
  },

  async check({ commit, dispatch, state }) {
    console.log(state);
    logger.default.info("Checking authentication…");
    commit("authenticated", false);
    let profile = await dispatch("fetchProfile");
    if (profile) {
      commit("authenticated", true);
    } else {
      logger.default.info("Anonymous User");
    }
  },

  fetchProfile({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      axios.get("auth/user/").then(
        (response) => {
          logger.default.info("Successfully fetched User profile");
          dispatch("updateProfile", response.data).then(() => {
            resolve(response.data);
          });
          // dispatch('ui/fetchUnreadNotifications', null, { root: true });
          // if (response.data.permissions.library) {
          //     dispatch('ui/fetchPendingReviewEdits', null, { root: true });
          // }
          // if (response.data.permissions.moderation) {
          //     dispatch('ui/fetchPendingReviewReports', null, { root: true });
          //     dispatch('ui/fetchPendingReviewRequests', null, { root: true });
          // }
          // dispatch('favorites/fetch', null, { root: true });
          // dispatch('channels/fetchSubscriptions', null, { root: true });
          // dispatch('libraries/fetchFollows', null, { root: true });
          // dispatch('moderation/fetchContentFilters', null, { root: true });
          // dispatch('playlists/fetchOwn', null, { root: true });
        },
        () => {
          localStorage.removeItem("token");
          localStorage.removeItem("refresh");
          logger.default.info("Error while fetching User profile");
          reject();
        }
      );
    });
  },

  updateProfile({ commit }, user) {
    return new Promise((resolve, reject) => {
      commit("authenticated", true);
      commit("user", user);
      if (user.shop) {
        commit("shop", user.shop);
      } else if (user.is_admin) {
        commit("admin", user.is_admin);
      }

      resolve();
    });
  },

  updateToken({ commit }, data) {
    commit("updateToken", data);
  },
};

const mutations = {
  setAuthUser(state, { authUser, isAuthenticated }) {
    Vue.set(state, "authUser", authUser);
    Vue.set(state, "isAuthenticated", isAuthenticated);
  },
  updateToken(state, newToken) {
    localStorage.setItem(TOKEN_STORAGE_KEY, newToken);
    // TODO: For security purposes, take localStorage out of the project.
    axios.defaults.headers["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    state.token = newToken;
  },
  setToken(state, token) {
    if (!isProduction) {
      localStorage.setItem(TOKEN_STORAGE_KEY, token);
    }
    axios.defaults.headers.Authorization = `Bearer ${token}`;
    state.token = token;
  },
  removeToken(state) {
    // TODO: For security purposes, take localStorage out of the project.
    localStorage.removeItem(TOKEN_STORAGE_KEY);
    delete axios.defaults.headers.Authorization;
    state.token = null;
  },
  user(state, user) {
    state.user = user;
  },
  reset(state) {
    state.isAuthenticated = false;
    state.isMerchant = false;
    state.token = "";
    state.user = null;
    state.shop = null;
  },
  profile: (state, value) => {
    state.profile = value;
  },
  authenticated: (state, value) => {
    state.isAuthenticated = value;
    if (value === false) {
      state.user = null;
      state.token = null;
    }
  },
  shop(state, shop) {
    state.shop = shop;
    state.isMerchant = true;
  },
  admin(state, isAdmin) {
    state.isAdmin = true;
  },
  next(state, nextPage) {
    state.next = nextPage;
  },
};

export default {
  // We're using namespacing
  // in all of our modules.
  namespaced: true,
  mutations,
  actions,
  getters,
  state,
};
