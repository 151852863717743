export default function auth({ next, router }) {
   let vueState = localStorage.getItem('vuex')
   if (vueState) {
      try {
         const state = JSON.parse(vueState)
         let isAuthenticated = false;
         let isAdmin = false;
         if (state.auth) {
            isAuthenticated = state.auth.isAuthenticated
            isAdmin = state.auth.isAdmin
         }
         console.log(isAdmin)
         if (!isAdmin) {
            // location.href = '/admin';
         }
      } catch (e) {
         // location.href = '/login';
      }
   } else if (!localStorage.getItem('token')) {
      location.href = '/login';
   }

   return next();
}

// import jwtDecode from 'jwt-decode';
//
// function redirectToMainPage(next) {
//    // You can display a notification here if needed
//    return next({path: '/'});
// }
//
// export default function auth(router) {
//    router.beforeEach((to, from, next) => {
//       // check for the routes that have `requiresAuth: true`
//       let requiresAuth = to.matched.some(record => record.meta.requiresAuth);
//       if (!requiresAuth) {
//          return next();
//       }
//       let token = localStorage.getItem('token');
//       if (!token) {
//          return next({path: '/login'});
//       }
//       try {
//          let decodedToken = jwtDecode(token);
//          let { user, role } = decodedToken;
//          let { role: metaRole } = to.meta;
//          let userId = localStorage.getItem('userId');
//          if (user === userId) {
//             if (role && metaRole && role !== metaRole){
//                return redirectToMainPage(next);
//             }
//             return next()
//          } else {
//             redirectToMainPage(next);
//          }
//       } catch (err) {
//          redirectToMainPage(next);
//       }
//    })
// }
